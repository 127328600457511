<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocCancelados.table"
      :enabledActions="false"
      :nameToExport="'Documentos Cancelados'"
    >
      <template v-slot:column>
        <DxColumn data-field="modelo" alignment="right" />
        <DxColumn data-field="serie" alignment="right" caption="Série" />
        <DxColumn data-field="numero" alignment="right" caption="Número" />
        <DxColumn
          data-field="chave"
          alignment="center"
          caption="Chave Acesso"
        />
        <DxColumn data-field="emissao" data-type="date" caption="Emissão" />
        <DxColumn
          data-field="tipoDocumento"
          alignment="center"
          caption="Tipo"
          :calculate-cell-value="(rowData) => {return Validators.formataTipo(rowData.tipoDocumento)}"
        />
        <DxColumn
          data-field="finalidade"
          alignment="center"
          :calculate-cell-value="(rowData) => {return Validators.formataFinalidade(rowData.finalidade)}"
        />
        <DxColumn
          data-field="valor"
          data-type="number"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="cancelamento"
          data-type="number"
          caption="Protocolo Cancelamento"
        />
      </template>
      <template v-slot:footer>
        <DxExport :enabled="true" />
        <DxSummary>
          <DxTotalItem
            column="valor"
            alignment="right"
            summary-type="sum"
            display-format="{0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
        </DxSummary>
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>